:root {
  --black: #272b2d;
  --blue: #00adff;
  --dark-blue: #10275B;
  --gray: #626262;
  --gray-light: #e3e8eb;
  --grayish-blue: #515c61;
  --red: #ff5f5f;
  --white: #fff;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.23px;
  line-height: 1.25;
}

.no-user-select {
  user-select: none;
}

.color-gray {
  color: var(--gray);
}

.color-black {
  color: var(--black);
}

.color-blue {
  color: var(--blue);
}

.color-red {
  color: var(--red);
}

.color-grayish-blue {
  color: var(--grayish-blue);
}

.font-size-21 {
  font-size: 21px;
  letter-spacing: 0.2px;
}

.font-size-30 {
  font-size: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-all {
  pointer-events: all;
}

.border-red {
  border: 1px solid var(--red)!important;
}

/* normilize carousel */
ul {
  padding-inline-start: 0;
}
